<template>
    <div class="content">
        <BreadCrumbs
            :elements="[{ link: 'Compare', name: 'Összehasonlítás' }]"
        />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-holder">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-1-' + index"
                                        >
                                            <div class="promo-img">
                                                <img
                                                    :src="
                                                        'http://images.akh.hu/mintazatok/' +
                                                            productData.kepnev
                                                    "
                                                    class="w-50 d-block mx-auto"
                                                    :alt="
                                                        productData.emarka +
                                                            'GUMIABRONCS'
                                                    "
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                    <!-- annyi tr van amennyi tulajdonság és annyi td+1 ahány termék-->
                                    <tr>
                                        <td><strong>Márka</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-2-' + index"
                                        >
                                            {{ productData.emarka }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Méret</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-3-' + index"
                                        >
                                            {{ productData.width }}/{{
                                                productData.profile
                                            }}R{{ productData.coll }}
                                            <span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Abroncs típus</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-4-' + index"
                                        >
                                            {{ productData.m2nev }} -
                                            {{ productData.m1nev }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Sebesség index</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-5-' + index"
                                        >
                                            {{ productData.si }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Fogyasztás</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-6-' + index"
                                        >
                                            {{ productData.fogyasztas }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Fékezés</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-7-' + index"
                                        >
                                            {{ productData.fekezes }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Külső zaj</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-8-' + index"
                                        >
                                            {{ productData.zaj }} DB
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Értékelés</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-9-' + index"
                                        >
                                            <div>
                                                <img
                                                    v-for="(item,
                                                    index) in productData.stars"
                                                    v-bind:key="'star-' + index"
                                                    src="/img/star_in.svg"
                                                    class="small-icon"
                                                />
                                                <img
                                                    v-for="(item, index) in 5 -
                                                        parseInt(
                                                            productData.stars
                                                        )"
                                                    v-bind:key="
                                                        'star-out-' + index
                                                    "
                                                    src="/img/star_out.svg"
                                                    class="small-icon"
                                                />
                                            </div>
                                            {{ productData.stars }} csillagos
                                            értékelés
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Készlet</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-10-' + index"
                                        >
                                            <div>
                                                <img
                                                    src="/img/tyre_in.svg"
                                                    class="small-icon"
                                                    v-if="productData.tat >= 1"
                                                />
                                                <img
                                                    src="/img/tyre_in.svg"
                                                    class="small-icon"
                                                    v-if="productData.tat >= 2"
                                                />
                                                <img
                                                    src="/img/tyre_in.svg"
                                                    class="small-icon"
                                                    v-if="productData.tat >= 3"
                                                />
                                                <img
                                                    src="/img/tyre_in.svg"
                                                    class="small-icon"
                                                    v-if="productData.tat >= 4"
                                                />
                                                <img
                                                    src="/img/plus.svg"
                                                    class="small-icon"
                                                    v-if="productData.tat > 4"
                                                />
                                            </div>
                                            Raktáron
                                            <template
                                                v-if="productData.tat <= 6"
                                                >{{ productData.tat }}</template
                                            ><template v-else>4+</template>
                                            darab
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Ár</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-11-' + index"
                                        >
                                            <div class="price-box text-end">
                                                <div
                                                    class="price-original"
                                                    v-if="
                                                        productData.special_price !==
                                                            0
                                                    "
                                                >
                                                    Eredeti ár:
                                                    {{
                                                        productData.calculated_netto
                                                    }}
                                                    Ft
                                                </div>
                                                <div
                                                    class="price-actual"
                                                    v-if="
                                                        productData.special_price !==
                                                            0
                                                    "
                                                >
                                                    {{
                                                        productData.calculated_special_price
                                                    }}
                                                    Ft
                                                </div>
                                                <div
                                                    class="price-actual"
                                                    v-else
                                                >
                                                    {{
                                                        productData.calculated_netto
                                                    }}
                                                    Ft
                                                </div>
                                                <div
                                                    class="price-discount"
                                                    v-if="
                                                        productData.special_price !==
                                                            0
                                                    "
                                                >
                                                    -{{
                                                        productData.calculated_percent
                                                    }}% <br />kedvezmény
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Kosárba rakás:</strong></td>
                                        <td
                                            v-for="(productData,
                                            index) in model"
                                            v-bind:key="'ind-12-' + index"
                                        >
                                            <button
                                                class="btn btn-primary list-input-padding add-to-cart d-flex align-items-center justify-content-between"
                                            >
                                                Kosárba
                                                <img
                                                    src="/img/cart.svg"
                                                    class="list-carticon"
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";

export default {
    name: "Compare",
    components: { BreadCrumbs },
    data() {
        return {
            model: {}
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        }
    },
    beforeMount() {
        this.getSearchData();
    },
    methods: {
        getSearchData() {
            ApiService.get(
                `${this.url}/products/compare?${this.buildQueryString(
                    this.$route.query
                )}`
            ).then(response => {
                this.model = response.data.data;
            });
        }
    }
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('BreadCrumbs',{attrs:{"elements":[{ link: 'Compare', name: 'Összehasonlítás' }]}}),_c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-holder"},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('th'),_vm._l((_vm.model),function(productData,index){return _c('th',{key:'ind-1-' + index},[_c('div',{staticClass:"promo-img"},[_c('img',{staticClass:"w-50 d-block mx-auto",attrs:{"src":'http://images.akh.hu/mintazatok/' +
                                                        productData.kepnev,"alt":productData.emarka +
                                                        'GUMIABRONCS'}})])])})],2),_c('tr',[_vm._m(0),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-2-' + index},[_vm._v(" "+_vm._s(productData.emarka)+" ")])})],2),_c('tr',[_vm._m(1),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-3-' + index},[_vm._v(" "+_vm._s(productData.width)+"/"+_vm._s(productData.profile)+"R"+_vm._s(productData.coll)+" "),_c('span')])})],2),_c('tr',[_vm._m(2),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-4-' + index},[_vm._v(" "+_vm._s(productData.m2nev)+" - "+_vm._s(productData.m1nev)+" ")])})],2),_c('tr',[_vm._m(3),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-5-' + index},[_vm._v(" "+_vm._s(productData.si)+" ")])})],2),_c('tr',[_vm._m(4),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-6-' + index},[_vm._v(" "+_vm._s(productData.fogyasztas)+" ")])})],2),_c('tr',[_vm._m(5),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-7-' + index},[_vm._v(" "+_vm._s(productData.fekezes)+" ")])})],2),_c('tr',[_vm._m(6),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-8-' + index},[_vm._v(" "+_vm._s(productData.zaj)+" DB ")])})],2),_c('tr',[_vm._m(7),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-9-' + index},[_c('div',[_vm._l((productData.stars),function(item,index){return _c('img',{key:'star-' + index,staticClass:"small-icon",attrs:{"src":"/img/star_in.svg"}})}),_vm._l((5 -
                                                    parseInt(
                                                        productData.stars
                                                    )),function(item,index){return _c('img',{key:'star-out-' + index,staticClass:"small-icon",attrs:{"src":"/img/star_out.svg"}})})],2),_vm._v(" "+_vm._s(productData.stars)+" csillagos értékelés ")])})],2),_c('tr',[_vm._m(8),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-10-' + index},[_c('div',[(productData.tat >= 1)?_c('img',{staticClass:"small-icon",attrs:{"src":"/img/tyre_in.svg"}}):_vm._e(),(productData.tat >= 2)?_c('img',{staticClass:"small-icon",attrs:{"src":"/img/tyre_in.svg"}}):_vm._e(),(productData.tat >= 3)?_c('img',{staticClass:"small-icon",attrs:{"src":"/img/tyre_in.svg"}}):_vm._e(),(productData.tat >= 4)?_c('img',{staticClass:"small-icon",attrs:{"src":"/img/tyre_in.svg"}}):_vm._e(),(productData.tat > 4)?_c('img',{staticClass:"small-icon",attrs:{"src":"/img/plus.svg"}}):_vm._e()]),_vm._v(" Raktáron "),(productData.tat <= 6)?[_vm._v(_vm._s(productData.tat))]:[_vm._v("4+")],_vm._v(" darab ")],2)})],2),_c('tr',[_vm._m(9),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-11-' + index},[_c('div',{staticClass:"price-box text-end"},[(
                                                    productData.special_price !==
                                                        0
                                                )?_c('div',{staticClass:"price-original"},[_vm._v(" Eredeti ár: "+_vm._s(productData.calculated_netto)+" Ft ")]):_vm._e(),(
                                                    productData.special_price !==
                                                        0
                                                )?_c('div',{staticClass:"price-actual"},[_vm._v(" "+_vm._s(productData.calculated_special_price)+" Ft ")]):_c('div',{staticClass:"price-actual"},[_vm._v(" "+_vm._s(productData.calculated_netto)+" Ft ")]),(
                                                    productData.special_price !==
                                                        0
                                                )?_c('div',{staticClass:"price-discount"},[_vm._v(" -"+_vm._s(productData.calculated_percent)+"% "),_c('br'),_vm._v("kedvezmény ")]):_vm._e()])])})],2),_c('tr',[_vm._m(10),_vm._l((_vm.model),function(productData,index){return _c('td',{key:'ind-12-' + index},[_vm._m(11,true)])})],2)])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Márka")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Méret")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Abroncs típus")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Sebesség index")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Fogyasztás")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Fékezés")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Külső zaj")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Értékelés")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Készlet")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Ár")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('strong',[_vm._v("Kosárba rakás:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn btn-primary list-input-padding add-to-cart d-flex align-items-center justify-content-between"},[_vm._v(" Kosárba "),_c('img',{staticClass:"list-carticon",attrs:{"src":"/img/cart.svg"}})])}]

export { render, staticRenderFns }